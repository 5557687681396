<template>
  <section class="">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Add Promotion Codes</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/promo-codes/promo-code-list" class="text-primary">
              Promotion Codes
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Promotion Codes</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addPromo">
              <div class="mb-1">
                <!-- <label class="border-order-label">Add Promo Codes</label> -->
                <div class="" >
                  <div class="pt-1">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Code</label>
                              <input
                                v-model="promo.code"
                                type="text"
                                class="form-control"
                                :class="{
                                  'is-invalid': submitted && $v.promo.code.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.promo.code.required"
                                class="invalid-feedback"
                              >
                                Code is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Amount</label>
                              <input
                                v-model="promo.amount"
                                type="number"
                                class="form-control"
                                :class="{
                                  'is-invalid': submitted && $v.promo.amount.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.promo.amount.required"
                                class="invalid-feedback"
                              >
                                Amount is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Percentage</label>
                              <input
                                type="number"
                                v-model="promo.percentage"
                                class="form-control"
                                :class="{
                                  'is-invalid': submitted && $v.promo.percentage.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.promo.percentage.required"
                                class="invalid-feedback"
                              >
                                Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Total</label>
                              <input
                                type="number"
                                v-model="promo.total"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Qty</label>
                              <input
                                type="nubmer"
                                v-model="promo.qty"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Expiration Date</label>
                              <date-picker
                                v-model="promo.expiration_data"
                                format="YYYY-MM-DD"
                                valueType="format"
                              ></date-picker>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="form-group">
                              <label for="name"> Description</label>
                              <textarea
                                v-model="promo.description"
                                class="form-control"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-2 pb-1">
                              <label for="name" class="pr-2 pt-1">Active</label>
                              <label class="switch">
                                <input type="checkbox" v-model="promo.active" />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 pt-1">
                        <button class="btn btn-primary btn-md btn-block">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const orderApiUrl = process.env.VUE_APP_BASE_URL;
import DatePicker from "vue2-datepicker";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loader from "../../components/Loader";
export default {
  name: "add-new-content",
  components: {
    Loader,
    DatePicker,
  },
  data() {
    return {
      promo: {
        active: true,
        code: "",
        amount: "",
        percentage: "",
      },
      submitted: false,
      showLoader: false,
    };
  },
  validations: {
    promo: {
      code: { required },
      amount: { required },
      percentage: { required },
    },
  },
  methods: {
    async addPromo() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.promo.active === true) {
        this.promo.active = 1;
      } else {
        this.promo.active = 0;
      }
      this.showLoader = true;
      axios
        .post(`${orderApiUrl}/api/promotions/code/create`, this.promo, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.showLoader = false;
          if (response.data.code == 200) {
            this.$router.push("/promo-codes/promo-code-list");
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              type: "success",
              title: " ",
              text: "Successfully Uploaded",
            });
          } else {
            this.$swal({
              type: "error",
              title: "Oops...",
              text: "The given data was invalid",
            });
          }
          // console.log(response, 'ddd');
        });
    },
  },
};
</script>
<style>
/* .border {
  border: 1px solid #2125299e !important;
} */
</style>
